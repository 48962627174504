/*
 * Copyright (C) 2021 - present by OpenGamma Inc. and the OpenGamma group of companies
 *
 * Please see distribution for license.
 */

import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule } from '@angular/common/http';

import { AppComponent } from 'app/components/app/app.component';
import { HeaderComponent } from 'app/components/header/header.component';
import { GridComponent } from 'app/components/grid/grid.component';
import { NotificationInputsComponent } from 'app/components/notification-inputs/notification-inputs.component';

import { NotificationsUrlDataProviderService } from 'app/services/url-data-provider.service';

import { FlexLayoutModule } from '@angular/flex-layout';
import {
  EntryFieldModule,
  IconModule,
  OGFormsModule,
  OGGridModule,
  OGMiscModule
} from '@opengamma/ui';
import {
  MAT_LEGACY_TOOLTIP_DEFAULT_OPTIONS as MAT_TOOLTIP_DEFAULT_OPTIONS,
  MatLegacyTooltipModule as MatTooltipModule
} from '@angular/material/legacy-tooltip';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { NotificationSubmissionService } from 'app/services/notification-submission.service';
import { SafeHtmlPipe } from 'app/safe-html.pipe';

@NgModule({
  imports: [
    BrowserModule,
    HttpClientModule,
    BrowserAnimationsModule,
    FlexLayoutModule,
    MatTooltipModule,
    MatButtonToggleModule,
    OGGridModule,
    OGMiscModule,
    OGFormsModule,
    EntryFieldModule,
    IconModule
  ],
  bootstrap: [AppComponent],
  declarations: [
    AppComponent,
    HeaderComponent,
    GridComponent,
    NotificationInputsComponent,
    SafeHtmlPipe
  ],
  providers: [
    NotificationsUrlDataProviderService,
    NotificationSubmissionService,
    {
      provide: MAT_TOOLTIP_DEFAULT_OPTIONS,
      useValue: {
        showDelay: 0,
        hideDelay: 0,
        touchendHideDelay: 1000,
        position: 'above'
      }
    }
  ]
})
export class AppModule {}
